import React, { FC, useEffect, useMemo, useRef } from 'react';
import { motion } from 'framer-motion';
import styled, { ThemeProvider } from 'styled-components';
import man_with_glasses from '../../images/man_with_glasses.png';
import maxGift from '../../images/max_group_gift.png';
import chooseGift from '../../images/initiator_choosegift.png';

import MetaTags from '../../components/MetaTags';

import { theme } from '../../styles/theme';
import { Ticker } from '../../sections/occasion/HeaderStyles';
import useMeaningfulGroupGifts from '../../sections/ecards/hooks/useMeaningfullGroupGifts';
import LinkButton from '../../components/LinkButton';
import { PORTRAIT_URLS } from '../../data/portraits';
import useFormFactors from '../../helpers/useFormFactors';
import { ObjectEntries, slack_products } from '../../sections/common/data/gifts';
import wavyBg from '../../images/wavy_bg.svg';
import { GIFTS_LINK, MARKETING_LINK, SLACK_PRICING_LINK, TEAMS_LINK } from '../../constants/links';
import illumeLogo from '../../icons/illume.svg';
import { Link, navigate } from 'gatsby';
import img_1 from '../../images/onboarding/create_card_now.png';
import img_2 from '../../images/onboarding/illume_app.png';
import img_3 from '../../images/onboarding/upcoming_cards.png';
const Layout = styled(motion.div)`
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(to bottom, #fffdf3 28%, #f9f5e8 28%);
  position: relative;

  & > .content {
    max-width: 1100px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    -webkit-backface-visibility: hidden;
  }
`;

const ImageAndShadowContainer = styled.div<{ shadowColor: string }>`
  img {
    display: block;
    width: 100%;
    border-radius: 8px;
    box-shadow: ${(props) => props.shadowColor} -6px 6px 0px 0px;
  }
`;

const ImageAndShadow = ({
  src,
  alt,
  shadowColor,
}: {
  src: string;
  alt: string;
  shadowColor: string;
}) => {
  return (
    <ImageAndShadowContainer shadowColor={shadowColor}>
      <img src={src} alt={alt} />
    </ImageAndShadowContainer>
  );
};

const UpperSection = styled.div`
  margin-top: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding: 0 10px;
  h1 {
    font-size: 36px;
    @media screen and (min-width: 320px) {
      font-size: 42px;
    }

    @media screen and (min-width: 768px) {
      font-size: 58px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 64px;
    }
    text-align: center;
    font-weight: 450;
    line-height: 1.2;
    color: #282829;
  }
  p {
    font-size: 14px;
    color: #565659;
    text-align: center;
  }
`;

const StyledNotesCount = styled(Ticker)`
  padding: 0;
  margin-top: 12px;
`;

const ExploreLink = styled(LinkButton)`
  margin: 16px auto 0 auto;
  width: 100%;
  font-weight: normal;
  max-width: 300px;
  color: #000;
  font-size: 18px;
  font-weight: 450;
  line-height: 26px;
  &:hover {
    background-color: #e5a619;
  }
`;

const FloatingImageContainer = styled.div`
  position: relative;
  width: 320px;
  height: 290px;
  display: flex;
  flex-direction: column;
  .img {
    position: absolute;
    z-index: 1;
    &:nth-child(1) {
      top: 0;
      left: 0;
      width: 157px;
      height: 82px;
    }
    &:nth-child(2) {
      top: calc(82px / 2);
      left: calc(157px + 10px);
      width: 137px;
      height: 82px;
    }
    &:nth-child(3) {
      top: calc(82px + 10px);
      width: 124px;
      height: 80px;
    }
    &:nth-child(4) {
      width: 147px;
      height: 82px;
      left: calc(157px - 10px);
      top: calc(140px);
    }
  }
`;

const Wave = styled.div`
  background: url(${wavyBg}) no-repeat;
  background-position: 50% 240px;
  background-size: contain;
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const ImageRenderer = styled.div<{ src: string }>`
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 34.38%, rgba(0, 0, 0, 0.4) 84.9%),
    url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 50%;
  border-radius: 8px;
  overflow: hidden;
  padding: 8px;
  padding-right: 10px;
  padding-bottom: 6px;
  display: flex;
`;

const LowerSection = styled.div`
  display: flex;
  padding: 0 50px;
  flex-direction: column;
  row-gap: 60px;
  margin-top: 143px;
  margin-bottom: 68px;
  z-index: 1;
  @media screen and (min-width: 768px) {
    padding: 0;
    row-gap: 40px;
  }
`;

const ImageAndCopy = styled.div<{ direction: 'reverse' | 'normal' }>`
  display: flex;
  flex-direction: column-reverse;
  row-gap: 32px;
  align-items: center;
  text-align: center;

  @media screen and (min-width: 768px) {
    text-align: left;
    column-gap: 42px;
    align-items: center;
    flex-direction: ${(props) => (props.direction === 'reverse' ? 'row-reverse' : 'row')};
  }
`;

const Section = styled(motion.div)<{ variant: 'left' | 'right' | 'center' }>`
  display: flex;
  justify-content: center;
  @media screen and (min-width: 768px) {
    justify-content: ${(props) => (props.variant === 'left' ? 'flex-start' : 'flex-end')};
  }
`;

const SectionCopy = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 768px) {
    align-items: flex-start;
  }
  a {
    cursor: default;
    font-size: 24px;
    @media screen and (min-width: 768px) {
      font-size: 32px;
    }
    color: #e8a000;
    font-weight: 450;
    line-height: 1.2;
    margin-bottom: 8px;
    // underline size
    text-decoration-thickness: 2px;
    svg {
      margin-left: 8px;
    }
  }
  p {
    font-size: 18px;
    font-weight: 390;
    line-height: 26px;
    max-width: 354px;
    color: #565659;
  }
`;

const NorthEastArrow = () => (
  <svg
    style={{ width: 18, height: 18 }}
    width='54'
    height='54'
    viewBox='0 0 54 54'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M0 0H54V54H47.9032V11L5.5 53L1 48.5L42.5 6.88235H0V0Z' fill='#E8A000' />
  </svg>
);

const Slack: FC<{
  location: Location;
}> = ({ location }) => {
  const ref = useRef(null);
  const { mobile } = useFormFactors();

  // Function to handle the link click event
  const handleLinkClick = () => {
    navigate(SLACK_PRICING_LINK + location.search);
  };

  // get search params
  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const appUrl = searchParams.get('appUrl') || '';
  const browserUrl = searchParams.get('browserUrl') || '';

  console.log('appUrl', appUrl);
  console.log('browserUrl', browserUrl);

  const fallbackOpenAppUrl = (fallbackUrl: string) => {
    if (document && fallbackUrl) {
      const link = document.createElement('a');
      link.href = fallbackUrl;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();

      // remove the link from the body after the link is clicked
      document.body.removeChild(link);
    }
  };

  const openAppUrl = (url: string, fallbackUrl?: string) => {
    if (document && url) {
      const iframe = document.createElement('iframe');
      iframe.src = url;

      iframe.width = '0';
      iframe.height = '0';
      iframe.style.display = 'none';
      iframe.style.visibility = 'hidden';

      if (fallbackUrl) {
        let fallbackTimeout = setTimeout(function () {
          // fallback using popup
          fallbackOpenAppUrl(fallbackUrl);
          //fallback using redirection
          fallbackTimeout = setTimeout(function () {
            window.location.href = fallbackUrl;
          }, 5000);
        }, 5000);
        window.addEventListener('blur', function () {
          console.log('fallback canceled');
          clearTimeout(fallbackTimeout);
        });
      }

      document.body.appendChild(iframe);
      iframe.parentNode?.removeChild(iframe);
    }
  };

  useEffect(() => {
    if (appUrl) {
      // window.history.replaceState({}, '', appUrl);
      if (window !== undefined) {
        const onFocus = () => {
          openAppUrl(appUrl, browserUrl);
          window.removeEventListener('focus', onFocus);
        };

        if (document.hasFocus && document.hasFocus()) {
          setTimeout(() => {
            onFocus();
          }, 2000);
        } else {
          window.addEventListener('focus', onFocus);
        }
      }
    }
  }, [appUrl, browserUrl]);

  return (
    <ThemeProvider theme={theme}>
      <MetaTags
        title='The Celebration Platform | illume'
        description={
          'Create fun, stand-out ecards, group cards, and group gifts with illume. For birthdays, anniversaries, or any occasion. Join the new way of celebrating.'
        }
      />
      <Layout>
        <Link
          style={{
            position: 'absolute',
            top: 25,
            left: 29,
            width: 52,
            zIndex: 2,
          }}
          to='/'
        >
          <img src={illumeLogo} alt='' />
        </Link>
        <Wave />
        <div className='content'>
          <UpperSection>
            <h1>illume is the celebration platform</h1>
            <StyledNotesCount>
              <strong>{'>300k'}</strong> notes sent with love &#10084;&#65039;
            </StyledNotesCount>
            <ExploreLink onClick={handleLinkClick}>Continue Onboarding</ExploreLink>
          </UpperSection>
          <LowerSection>
            <Section
              animate={{ opacity: 1, x: 0 }}
              initial={{ opacity: 0, x: mobile ? 0 : -100 }}
              transition={{ duration: 0.5 }}
              variant='left'
            >
              <ImageAndCopy direction='normal'>
                <div style={{ position: 'relative', maxWidth: 400 }}>
                  <ImageAndShadow
                    src={img_1}
                    alt='illume card dashboard'
                    shadowColor='rgba(113, 87, 114, 1)'
                  />
                </div>
                <SectionCopy>
                  <a href={'/'}>Add unlimited contributors</a>
                  <p>
                    Save time with our dynamic group cards, mark any occasion together, no matter
                    where you are.
                  </p>
                </SectionCopy>
              </ImageAndCopy>
            </Section>
            <Section
              animate={{ opacity: 1, x: 0 }}
              initial={{ opacity: 0, x: mobile ? 0 : 100 }}
              transition={{ duration: 0.5 }}
              variant='right'
            >
              <ImageAndCopy direction='reverse'>
                <div style={{ position: 'relative', maxWidth: 400 }}>
                  <ImageAndShadow src={img_2} alt='illume card dashboard' shadowColor='none' />
                </div>
                <SectionCopy>
                  <a href={'/'}>Express yourself effortlessly</a>
                  <p>
                    Add photos, GIFs, videos, and more. Even a note suggestion library is here to
                    help you craft your message.
                  </p>
                </SectionCopy>
              </ImageAndCopy>
            </Section>
            <Section
              animate={{ opacity: 1, x: 0 }}
              initial={{ opacity: 0, x: mobile ? 0 : -100 }}
              transition={{ duration: 0.5 }}
              variant='left'
            >
              <ImageAndCopy direction='normal'>
                <div style={{ position: 'relative', maxWidth: 400 }}>
                  <ImageAndShadow
                    src={img_3}
                    alt='illume card dashboard'
                    shadowColor='rgba(255, 190, 167, 1)'
                  />
                </div>
                <SectionCopy>
                  <a href={TEAMS_LINK}>Bulk card creation for teams</a>
                  <p>
                    {`Create as many cards as you like - illume does all the logistics from notifying contributors to the recipient. `}
                  </p>
                </SectionCopy>
              </ImageAndCopy>
            </Section>
          </LowerSection>
        </div>
      </Layout>
    </ThemeProvider>
  );
};

const PictureContainer = styled.div<{ borderWidth: number; picture: string; index: number }>`
  position: absolute;
  border-radius: 12px;
  background: url(${(props) => props.picture});
  background-size: cover;
  background-position: center;
  width: 65px;
  height: 85px;
  border: 2px solid rgba(255, 190, 167, 1);
  bottom: ${(props) => props.index * -4}px;
  right: ${(props) => props.index * -4}px;
`;

export const StackOfPictures: React.FC<{
  pictures: { alt: string; url: string }[];
}> = ({ pictures }) => {
  return (
    <div style={{ position: 'relative' }}>
      {pictures.map((picture, index) => {
        const last = index === pictures.length - 1;
        return (
          <PictureContainer
            role={'img'}
            aria-label={picture.alt}
            title={picture.alt}
            borderWidth={last ? 2 : 4}
            key={index}
            picture={picture.url}
            index={index}
            style={{
              zIndex: pictures.length - index,
            }}
          ></PictureContainer>
        );
      })}
    </div>
  );
};

export default Slack;

import styled from 'styled-components';
import LinkButton from '../../components/LinkButton';

export const Layout = styled.section`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    padding: 32px 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    padding: 40px 0;
  }
`;

export const Section = styled.div<{ zIndex?: number; maxWidth?: number }>`
  position: relative;
  width: 100%;
  ${(props) => props.zIndex && `z-index: ${props.zIndex}`};
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px`};

  @media ${(props) => props.theme.breakpoints.tabletP} {
    width: 50%;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    width: 50%;
  }
`;

export const Body = styled.div`
  padding: 24px;
  width: 100%;
  z-index: 2;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 0 0 0 24px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 48px 0 0 64px;
  }
`;

export const Heading = styled.p`
  color: ${(props) => props.theme.colors.blackText};
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  margin: 0 0 16px 0;
  text-align: left;
  max-width: 510px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 44px;
    line-height: 55px;
    margin: 0 auto 0 0;
    max-width: 362px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 64px;
    line-height: 70px;
    max-width: 540px;
    margin: 0 auto 0 0;
  }
`;

export const Paragraph = styled.p`
  color: ${(props) => props.theme.colors.darkGray};
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0 0 16px 0;
  text-align: left;
  max-width: 485px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    font-size: 16px;
    line-height: 22px;
    margin: 16px 0 0 0;
    max-width: 372px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    font-size: 18px;
    line-height: 26px;
    margin: 16px 0 0 0;
    max-width: 480px;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 328px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    flex-direction: row;
    justify-content: flex-start;
    max-width: 456px;
  }
`;

export const GroupGiftLink = styled(LinkButton)`
  margin: 0;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 32px 0 0 0;
    max-width: 184px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 32px 0 0 0;
    max-width: 240px;
  }

  &:hover {
    background-color: #e5a619;
  }
`;

export const ExploreGiftsLink = styled(LinkButton)`
  background: ${(props) => props.theme.colors.lightPeach3};
  color: #000;
  width: 100%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin: 32px 0 0 0;
    max-width: 136px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin: 32px 0 0 0;
    max-width: 176px;
  }
`;

export const Ticker = styled.div`
  color: ${(props) => props.theme.colors.darkGray};
  display: flex;
  font-size: 14px;
  justify-content: center;
  line-height: 18px;
  margin: 8px 0 0 0;

  strong {
    padding: 0 4px 0 0;
  }

  @media ${(props) => props.theme.breakpoints.tabletP} {
    padding: 0 45% 0 0;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 0 55% 0 0;
  }
`;

export const Blob = styled.img`
  height: 905px;
  left: -280px;
  position: absolute;
  top: -160px;
  width: 849px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    height: 480px;
    left: -48px;
    position: absolute;
    top: 124px;
    width: 480px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    height: 800px;
    left: -132px;
    position: absolute;
    top: -26px;
    width: 800px;
  }
`;

export const ImageWrapper = styled.div`
  max-width: 485px;
  max-height: 485px;
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  position: relative;
  margin: 0 auto;

  @media ${(props) => props.theme.breakpoints.tabletP} {
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    padding: 48px 64px 0 0;
    margin: 0 0 0 auto;
  }
`;

export const CelebratingImage = styled.img`
  width: 90%;
  margin-left: 10%;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    margin-top: -28px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    margin-top: -28px;
  }
`;

export const PhoneImage = styled.img`
  bottom: 0;
  position: absolute;
  height: 80%;
  left: 15px;

  @media ${(props) => props.theme.breakpoints.tabletP} {
    bottom: -22px;
  }

  @media ${(props) => props.theme.breakpoints.laptop} {
    bottom: -22px;
  }
`;
